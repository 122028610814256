<template>
  <section id="dashboard-ecommerce">
    <div class="sec1 d-flex justify-content-between pb-1">
      <h1 class="text-black">Ad Placement{{ app }}</h1>
      <div>
        <button
          type="button"
          class="btn btn-primary"
          @click="saveChangesHandelClick"
          style="border-radius: 16px; padding: 16px"
        >
          Save Changes
        </button>
      </div>
    </div>
    <div class="drop-zone">
      <b-row class="p-1">
        <b-col md="2">
          <b-card no-body class="card-revenue-budget dash-total-card p-1" style="background: #ECF3FF">
            <div class="pb-1"><span style="font-weight:bolder;font-size:18px;color: #3F8CFF;">Placement</span></div>
            <div>
          <draggable v-model="adPlacementData" handle=".draggable-task-handle" @change="draggableChange($event)" tag="ul"
        class="todo-task-list media-list draggable-task-handle d-inline">
              <div v-for="item in adPlacementData" :key="item.id" class="pb-1" >
                <input
                disabled
                  v-model="item.name"
                  type="text"
                  class="form-control"
                />
              </div>
          </draggable>
            </div>
          </b-card>
        </b-col>
          <b-col md="2">
          <b-card no-body class="card-revenue-budget dash-total-card p-1" style="background: #FFFFFF">
            <div class="pb-1"><span class="text-bold" style="font-weight:bolder;font-size:18px;">per day changes</span></div>
            <div>
              <div v-for="item in adPlacementData" :key="item.id" class="pb-1" >
                <input
                  v-model="item.per_day_fee"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col md="2">
          <b-card no-body class="card-revenue-budget dash-total-card p-1" style="background: #FFFFFF">
            <div class="pb-1"><span class="text-bold" style="font-weight:bolder;font-size:18px;">5 days changes</span></div>
            <div>
              <div v-for="item in adPlacementData" :key="item.id" class="pb-1" >
                <input
                  v-model="item.five_day_fee"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
          </b-card>
        </b-col>
         <b-col md="2">
          <b-card no-body class="card-revenue-budget dash-total-card p-1" style="background: #FFFFFF">
            <div class="pb-1"><span class="text-bold" style="font-weight:bolder;font-size:18px;">10 days changes</span></div>
            <div>
              <div v-for="item in adPlacementData" :key="item.id" class="pb-1" >
                <input
                  v-model="item.ten_day_fee"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
          </b-card>
        </b-col>
          <b-col md="2">
          <b-card no-body class="card-revenue-budget dash-total-card p-1" style="background: #FFFFFF">
            <div class="pb-1"><span class="text-bold" style="font-weight:bolder;font-size:18px;">15 days changes</span></div>
            <div>
              <div v-for="item in adPlacementData" :key="item.id" class="pb-1" >
                <input
                  v-model="item.fifteen_day_fee"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col md="2">
          <b-card no-body class="card-revenue-budget dash-total-card p-1" style="background: #FFFFFF">
            <div class="pb-1"><span class="text-bold" style="font-weight:bolder;font-size:18px;">30 days changes</span></div>
            <div>
              <div v-for="item in adPlacementData" :key="item.id" class="pb-1" >
                <input
                  v-model="item.thirty_day_fee"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <div class="no-results" :class="{ show: !clientStoreData.length }"></div>
    </div>
    <!-- <button type="button" class="btn slide-btn">
      <div class="d-flex justify-content-around align-items-center">
        <p class="text-black" style="margin: 0">1-8 of 28</p>
        <feather-icon icon="ArrowLeftIcon" size="18" />
        <feather-icon icon="ArrowRightIcon" size="18" />
      </div>
    </button>

    <button
      v-if="popoverShow"
      id="seeall-btn"
      type="button"
      class="btn see-btn"
    >
      <div class="d-flex justify-content-around align-items-center">
        <p class="text-black" style="margin: 0">Select All</p>
      </div>
    </button> -->
  </section>
</template>

<script>
import draggable from "vuedraggable";
export default {
  components: {
    draggable,
  },
data() {
    return {
      data: {},
      clientStoreData: [
        { name: "Noon" },
        { name: "Sephora" },
        { name: "American Eagle" },
        { name: "Amazon" },
        { name: "Namshi" },
        { name: "H&M" },
      ],
      adPlacementData:[],
      adPlacementData1:[],
      baseURL: process.env.VUE_APP_BASE_URL,
      host:window.location.origin,
      popoverShow: false,
      popoverShow1: false,
      value: "1",
    };
  },
  computed: {
    app() {
      console.log('enter')
    setTimeout(() => {
        let is_valid = this.$store.state.sidebar.filter(data => data.name == 'AdPlacement')
        if(is_valid.length < 1){this.$router.push('/')}
    }, 500);
    let appId = this.$store.state.application.id
  },
  },
  mounted(){
    setTimeout(() => {
        let is_valid = this.$store.state.sidebar.filter(data => data.name == 'AdPlacement')
        if(is_valid.length < 1){this.$router.push('/')}
    }, 500);
    this.getClientStore();
  },
  methods: {
    saveChangesHandelClick(){
      this.$http.post( `${this.baseURL}/ad-placement/update`,{data:this.adPlacementData}).then((json)=>{
            console.log(json,"res-=-=")
            if(json.data.status == 201){
              this.$swal({
                title: "Updated",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                  ? `${json.data.success}`
                  : "Submitted SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              })
              this.getClientStore();
            }
            // this.adPlacementData = res.data.data
            // this.paginationLength = res.data.totaldata  
            // this.adPlacementData1 = res.data.data
            // this.isLoading = false
          })
        },
        
    draggableChange(e) {
      let data = {
        newIndex: this.adPlacementData1[e.moved.newIndex].sequence,
        oldIndex: this.adPlacementData1[e.moved.oldIndex].sequence
      }
      this.$http.post(`${this.baseURL}/ad-placement/sequence-change`, data,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((res) => {
          console.log(res.data.data, "res-=-=")
            this.adPlacementData = res.data.data
            this.adPlacementData1 = res.data.data
        })
    },
    async getClientStore(){
      this.isLoading = true
      this.$http.post(`${this.baseURL}/ad-placement/index`).then((res)=>{
            console.log(res.data.data,"res-=-=")
            this.adPlacementData = res.data.data
            this.adPlacementData1 = res.data.data
            this.isLoading = false
          })
        },
    onPopovcerOpen() {
      this.popoverShow = !this.popoverShow;
    },
    onPopovcerOpen1() {
      this.popoverShow1 = !this.popoverShow1;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";

.status-btn {
  width: 58px;
  padding: 3px 5px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #0ac947;
  border: 1px solid #0ac947;
  border-radius: 4px;
}
.mb-half {
  margin-bottom: 5px;
}
.notofication-btn {
  background-color: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  padding: 12px 12px;
}
.slide-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  padding: 15 px;
  margin: 0;
  width: 200px;
  float: right;
}
.see-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  width: 125px;
}
.bs-popover-left {
  border-radius: 14px !important;
  border: transparent;
  min-width: 140px !important;
  max-width: 350px !important;
}
.bs-popover-left .popover-body {
  display: flex;
  justify-content: space-between;
  border: none;
}
.bs-popover-bottom {
  background-color: #ffffff;
  // height: 190px;
  border: transparent;
  border: none;
  border-radius: 14px;
}
.popover-body {
  border: none !important;
}
.popover.bs-popover-bottom .arrow:after {
  border-bottom-color: #ffffff;
}
.vs-select__options__content {
  background-color: #ffffff !important;
}
.pop-btn {
  padding: 0 !important;
}
.modal-dialog {
 // max-width: 550px !important ;
}
.modal-body {
   padding: 0px 40px 40px 40px !important
}
.form-control {
  border-radius: 14px;
}
.modal-content {
  border-radius: 24px;
}
.modal-header {
  border-radius: 24px;
}
textarea.form-control {
  min-height: 150px !important;
}

.modal-footer {
  display: none;
}
.modal-footer1 {
  display: flex;
  float: right;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.8rem 1.4rem;
}
</style>
